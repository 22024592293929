import ALink from '../Shared/Link/ALink';

const AttributeDetailsInGrid = ({ attributes }) => {
  return (
    <div className="  mt-4 flex  justify-start  gap-10">
      {attributes?.map((attr) => {
        return (
          attr.visible &&
          attr.field_type !== 11 && (
            <div className="flex flex-row gap-2 " key={attr.id}>
              {attr?.icon_path && (
                <img className=" w-8 h-8 " src={attr?.icon_path} alt="" />
              )}

              <div className="flex flex-col gap-1 items-start justify-start">
                <p className="description-paragraph   font-semibold whitespace-nowrap ">
                  {attr.name}
                </p>

                {/* 1|2 */}
                {(attr.field_type === 1 || attr.field_type === 2) && (
                  <div className=" flex items-center justify-start gap-2 flex-wrap">
                    {attr.values.map((item) => {
                      if (attr.field_type === 1 || attr.field_type === 2) {
                        return attr?.show_in_filter ? (
                          <ALink
                            href={`/a?attribute_value_id=${item?.id}`}
                            styles={{
                              link: '  description-paragraph text-primary hover:underline duration-300',
                            }}
                          >
                            {item.name}
                          </ALink>
                        ) : (
                          <p className="overflow-auto  scrollbar-none   description-paragraph ">
                            {item.name}
                          </p>
                        );
                      }
                    })}
                  </div>
                )}

                {/* color type  */}
                {attr.field_type === 10 && (
                  <div className="flex items-center justify-start gap-2">
                    <div
                      className="w-6 h-6 rounded-full"
                      style={{ backgroundColor: attr.values[0] }}
                    ></div>
                  </div>
                )}

                {attr.field_type !== 1 &&
                  attr.field_type !== 2 &&
                  attr.field_type !== 6 &&
                  attr.field_type !== 10 && (
                    <p className="   whitespace-pre-line  description-paragraph  scrollbar-none  ">
                      {attr.values
                        .map((item) => {
                          if (attr.field_type === 1 || attr.field_type === 2) {
                            return item.name;
                          } else if (
                            attr.field_type === 3 ||
                            attr.field_type === 4
                          ) {
                            return item;
                          } else if (attr.field_type === 12) {
                            return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                          } else {
                            return item;
                          }
                        })
                        .join(', ')}
                    </p>
                  )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default AttributeDetailsInGrid;
