import React from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Account_text } from '../../constant/Translation/Account';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import CustomLoading from '../Shared/Loading/CustomLoading';

const AccountLocationWithMap = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {account_details?.coordinates?.latitude &&
        account_details?.coordinates?.longitude && (
          <LoadScript
            googleMapsApiKey={TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key}
            loadingElement={CustomLoading}
          >
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '300px' }}
              center={{
                lat: account_details?.coordinates?.latitude,
                lng: account_details?.coordinates?.longitude,
              }}
              zoom={10}
            >
              <Marker
                position={{
                  lat: account_details?.coordinates?.latitude,
                  lng: account_details?.coordinates?.longitude,
                }}
                clickable
              />
            </GoogleMap>
          </LoadScript>
        )}
    </div>
  );
};

export default AccountLocationWithMap;
