import React from 'react';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

const AccountLocation = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <p style={get_dynamic_styles(styles?.text_styles)}>
        {account_details?.location?.formatted_address
          ? account_details?.location?.formatted_address
          : account_details?.location?.city
          ? `${account_details?.location?.city} ${account_details?.location?.country}`
          : account_details?.location?.country}
      </p>
    </div>
  );
};

export default AccountLocation;
